import { useEffect, useState } from 'react'

import { ApiErrorsCodes } from '@jume/core'
import { useQuery } from '@tanstack/react-query'
import { systemUpdatingApi } from 'packages/app/modules/systemUpdating/api'
import { checkUpdateStatusInterval } from 'packages/app/modules/systemUpdating/constants'

import { SystemUpdatingQueries } from './types'

export const useSystemUpdatingQuery = () => {
  const [isRefetching, setIsRefetching] = useState(false)

  const {
    data,
    error,
    refetch: refetchInternal,
  } = useQuery({
    queryKey: [SystemUpdatingQueries.SystemUpdating],
    queryFn: systemUpdatingApi.getSystemUpdatingStatus,
    meta: {
      error: {
        showToast: false,
      },
    },
    refetchInterval: isRefetching ? checkUpdateStatusInterval : undefined,
  })

  const refetch = () => {
    refetchInternal()
    setIsRefetching(true)
  }

  useEffect(() => {
    setIsRefetching(!!data?.status)
  }, [data])

  useEffect(() => {
    if (error?.code === ApiErrorsCodes.UnknownError) {
      setIsRefetching(true)
    }
  }, [error])

  return {
    data,
    refetch,
    error,
  }
}
