import { t } from '@jume/localization'

export enum CaseOptions {
  Finished = 'Finished',
  Cancelled = 'Cancelled',
  Failed = 'Failed',
}

export enum DayIndexesOptions {
  First = 'First',
  Second = 'Second',
  Third = 'Third',
  Fourth = 'Fourth',
  Last = 'Last',
}

export enum TemplateOptions {
  ST = 'ST',
  MT = 'MT',
  LT = 'LT',
}

export enum MonthsOptions {
  January = 'January',
  February = 'February',
  March = 'March',
  April = 'April',
  May = 'May',
  June = 'June',
  July = 'July',
  August = 'August',
  September = 'September',
  October = 'October',
  November = 'November',
  December = 'December',
}

export const exportSystemOptions = ['APO', 'BI']
export const exportDeploySystemOptions = ['Deploy']

export const exportTemplateOptions = ['ST', 'LT']
export const exportDeployTemplateOptions = ['Forecast']

export const periodTypes = [
  {
    value: 1,
    label: t('jobCreate.float'),
  },
  {
    value: 2,
    label: t('jobCreate.fixed'),
  },
]
